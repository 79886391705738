module services {
    export module documentManagement {
        export class documentTypeService implements interfaces.documentManagement.IDocumentTypeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDocumentTypes(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "DocumentType/GetDocumentTypes", {
                    repTypeId: '@repTypeId',
                    referenceNumber: '@referenceNumber',
                    ownerEntityId: '@ownerEntityId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            GetDocumentTypesForDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "DocumentType/GetDocumentTypesForDropdownList", {
                    ownerEntityId: '@ownerEntityId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            GetRepositoriesForDocumentType(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "DocumentType/GetRepositoriesForDocumentType", {
                    docTypeId: '@docTypeId',
                    repTypeId: '@repTypeId',
                    referenceNumber: '@referenceNumber',
                    ownerEntityId: '@ownerEntityId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            GetRelatedTransactionsForRepositories(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "DocumentType/GetRelatedTransactionsForRepositories", {
                    selRepTypeId: '@selRepTypeId',
                    repTypeId: '@repTypeId',
                    referenceNumber: '@referenceNumber',
                    ownerEntityId: '@ownerEntityId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }
        }
    }
    angular.module("app").service("documentTypeService", services.documentManagement.documentTypeService);
}